import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import {
  AccountBalanceWallet,
  PriceCheck,
  Settings,
} from "@mui/icons-material";
import styled from "styled-components";
import { useUserStore } from "../../../store";
import { fontSize } from "@mui/system";

export default function ListItems({ isAgency, toggleDrawer }) {
  const { user } = useUserStore();
  const [openGroup, setOpenGroup] = React.useState(
    isAgency ? linksAgency : getLinksPersons(user)
  );
  const sx = {
    color: "white",
    "& .MuiListItemText-primary": {
      fontSize: "13px", // Cambia el tamaño del texto primario
    },
    "& .MuiListItemText-secondary": {
      fontSize: "1rem", // Cambia el tamaño del texto secundario
    },
  };

  const handleClickOpen = (item) => {
    item.state = !item.state;
    openGroup.map((p) => {
      if (p.nameItem !== item.nameItem) {
        p.state = false;
      }
    });
    setOpenGroup([...openGroup], item);
  };

  return (
    <List component="nav" sx={{ flexGrow: 1 }}>
      {openGroup.map((item) => (
        <main key={item.name}>
          {item.rol.some((role) => user.roles.includes(role)) && (
            <ListItemButton
              key={item.nameItem}
              onClick={() => handleClickOpen(item)}
            >
              <ListItemIcon>{item.icon()}</ListItemIcon>
              <ListItemText sx={sx}>{item.nameItem}</ListItemText>
              {item.state ? <ExpandLess sx={sx} /> : <ExpandMore sx={sx} />}
            </ListItemButton>
          )}
          {item.subItems &&
            item.subItems.map((j) => (
              <>
                <Collapse
                  key={j.name}
                  in={item.state}
                  timeout="auto"
                  unmountOnExit
                >
                  {j.nameItem ? (
                    <>
                      <ListItemButton
                        key={j.nameItem}
                        sx={{ pl: 10 }}
                        onClick={() => handleClickOpen(j)}
                      >
                        <ListItemIcon>{j.icon()}</ListItemIcon>
                        <ListItemText sx={sx}>{j?.nameItem}</ListItemText>
                        {j.state ? <ExpandLess sx={sx} /> : <ExpandMore />}
                      </ListItemButton>
                      {j.subItems &&
                        j.subItems.map((z) => (
                          <Collapse
                            key={z.name}
                            in={item.state}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 15 }}>
                                <StyledMUINavLink
                                  to={z.route}
                                  onClick={toggleDrawer}
                                >
                                  <ListItemText sx={sx}>{z.name}</ListItemText>
                                </StyledMUINavLink>
                              </ListItemButton>
                            </List>
                          </Collapse>
                        ))}
                    </>
                  ) : (
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 10 }}>
                        <StyledMUINavLink to={j.route} onClick={toggleDrawer}>
                          <ListItemText sx={sx}>{j.name}</ListItemText>
                        </StyledMUINavLink>
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              </>
            ))}
        </main>
      ))}
    </List>
  );
}

const getLinksPersons = (user) => [
  {
    nameItem: "Pagos",
    state: false,
    rol: ["Asesor", "Administrador"],
    icon: () => <AccountBalanceWallet sx={{ color: "white" }} />,
    subItems: [
      {
        name: "Realizar Pago",
        route: "/Dashboard/PersonPayments",
      },
      {
        name: "Lista de Pagos AUD-COP",
        route: "/Dashboard/PersonPaymentList",
      },
      {
        name: "Lista de Pagos COP-AUD",
        route: `/Dashboard/PaymentPerCopAud/${user.userId}`,
      },
    ],
  },
  {
    nameItem: "Control de Pagos",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Owner"],
    subItems: [
      {
        name: "Pagos Agencias",
        route: "/Dashboard/PaymentAdmin",
      },
      {
        name: "Pagos Personas AUD-COP",
        route: "/Dashboard/PaymentPerAudCop",
      },
      {
        name: "Personas Pay-Up",
        route: "/Dashboard/PayUpAdmin",
      },
      {
        name: "Pagos COP-AUD",
        route: "/Dashboard/PaymentPerCopAud/undefined",
      },
      {
        name: "Liquidar Pagos Agencias",
        route: "/Dashboard/AgencySettlePayments",
      },
    ],
  },
  {
    nameItem: "Virtual Account",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Asesor", "Administrador"],
    subItems: [
      {
        name: "Tus transacciones",
        route: "/Dashboard/PersonVirtualAccount",
      },
    ],
  },
  {
    nameItem: "Agencias",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Owner"],
    subItems: [
      {
        name: "Lista de Agencias",
        route: "/Dashboard/AgencyAdmin",
      },
    ],
  },
  {
    nameItem: "Configuraciones",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Owner"],
    subItems: [
      {
        name: "Configurar exchange rate",
        route: "/Dashboard/ExchangeAdmin",
      },
    ],
  },
  {
    nameItem: "Usuarios",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Owner"],
    subItems: [
      {
        name: "Lista de usuarios",
        route: "/Dashboard/UsersAdmin",
      },
    ],
  },
];

const linksAgency = [
  {
    nameItem: "Pagos",
    state: false,
    rol: ["Asesor", "Administrador"],
    icon: () => <AccountBalanceWallet sx={{ color: "white" }} />,
    subItems: [
      {
        name: "Realizar Pago",
        route: "/Dashboard/Payments",
      },
      {
        name: "Pagos Realizados",
        route: "/Dashboard/PaymentsList",
      },
    ],
  },
  {
    nameItem: "Liquidar",
    state: false,
    rol: ["Administrador"],
    icon: () => <PriceCheck sx={{ color: "white" }} />,
    subItems: [
      {
        name: "Liquidar Pagos",
        route: "/Dashboard/SettlePaymentsList",
      },
      {
        name: "Historial de liquidaciones",
        route: "/Dashboard/SettlePaymentHistory",
      },
    ],
  },
  {
    nameItem: "Configuraciones",
    state: false,
    rol: ["Administrador"],
    icon: () => <Settings sx={{ color: "white" }} />,
    subItems: [
      {
        name: "Configurar usuarios",
        route: "/Dashboard/CreateUserByAgency",
      },
    ],
  },
];

const StyledMUINavLink = styled(NavLink)`
  && {
    text-decoration: none;
    font-size: small;
  }
`;
