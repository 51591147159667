import { Grid, Link, Typography } from "@mui/material";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { StyledMUIBoxContainerForm } from "../../../StyledCrossComponents";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";

export const DashboardStats = ({ label, url, field, routeNavigate }) => {
  const navigate = useNavigate();
  const { data } = useFetchGet(url);
  if (!data) return null;

  const userLanguage = navigator.language || navigator.userLanguage;
  return (
    <>
      <StyledMUIBoxContainerForm width={"100%"}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              fontWeight={"bold"}
              color={theme.Palette.tertiary}
              mb={1}
            >
              {data[field].toLocaleString(userLanguage, {
                style: "currency",
                currency: data.currency || "AUD",
              })}
            </Typography>
          </Grid>
          <Grid container direction={"row"} spacing={1}>
            <Grid item>
              <DateRangeIcon fontSize="12px" />
            </Grid>
            <Grid item>
              <Typography fontSize={"12px"} color={theme.Palette.quaternary}>
                {`${data.dateStart.substring(0, 10)} - ${data.dateEnd.substring(
                  0,
                  10
                )}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <CurrencyExchangeIcon fontSize="12px" />
            </Grid>
            <Grid item>
              <Typography fontSize={"12px"} color={theme.Palette.quaternary}>
                {label}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <ZoomInIcon fontSize="12px" />
            </Grid>
            <Grid item>
              <Link
                style={{ cursor: "pointer" }}
                fontSize={"12px"}
                color={theme.Palette.quaternary}
                onClick={() => navigate(routeNavigate)}
              >
                Ver mas
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </StyledMUIBoxContainerForm>
    </>
  );
};
