import React, { useState, useEffect } from "react";
import { useStorageModal, useUserStore } from "../../../store";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import { DetailedSettlePayments } from "../../Owner/Components/DetailedSettlePayments";
import { CrossDataGrid } from "../../CrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFetchGet } from "../../../Hooks/useFetchGet";

export const SettlePaymentHistotyList = () => {
  const { setModalCross } = useStorageModal();
  const { user } = useUserStore();
  const [gridData, setGridData] = useState([]);
  const userLanguage = navigator.language || navigator.userLanguage;
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "fullNameReceiverEducationProv",
      headerName: "Provedor de educación",
      headerAlign: "center",

      flex: 2,
      minWidth: 200,
    },
    {
      field: "agencyName",
      headerName: "Agencia",
      headerAlign: "center",

      flex: 2,
      minWidth: 200,
    },
    {
      field: "date",
      headerName: "Fecha de liquidación",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const date = new Date(params.row.date);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "targetAmount",
      headerName: "Monto",
      renderCell: (params) => {
        return (
          <>
            {params.row.targetAmount.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.stellProvePaymenCurrency,
            })}
          </>
        );
      },
    },
  ];

  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <DetailedSettlePayments row={row} rol={user.roles} />,
      setValue: () => {},
      formValues: {},
    });
  };

  const { data } = useFetchGet(`AgencyPaymentOtherCountry/${user.agency.id}`);

  useEffect(() => {
    const filteredData = data?.filter(
      (item) => item.stateId === 5 || item.stateId === 4
    );
    setGridData(filteredData);
  }, [data]);

  return (
    <>
      <CrossDataGrid
        title={"Pagos liquidados"}
        columns={columns}
        data={gridData}
        rowId={"paymentReference"}
        sortModel={[
          {
            field: "date",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};
