import React from "react";
import { SettlePaymentList } from "../Components/SettlePaymentList";

export const AgencySettlePayments = () => {
  return (
    <>
      <SettlePaymentList />
    </>
  );
};
