import React from "react";
import { CrossDataGrid } from "../../CrossComponents";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { useUserStore } from "../../../store";

export const CommissionAgencyList = () => {
  const { user } = useUserStore();
  const userLanguage = navigator.language || navigator.userLanguage;
  const columns = [
    {
      field: "amountPayments",
      headerName: "Pagos realizados",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.amountPayments.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.currency,
            })}
          </>
        );
      },
    },
    {
      field: "amountPaymentCommission",
      headerName: "Comisión por pagos",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.amountPaymentCommission.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.currency,
            })}
          </>
        );
      },
    },
    {
      field: "dateStart",
      headerName: "Fecha de inicio",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return params.row.dateStart.substring(0, 10);
      },
    },
    {
      field: "dateEnd",
      headerName: "Fecha de fin",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return params.row.dateEnd.substring(0, 10);
      },
    },
  ];

  const { data } = useFetchGet(
    `Commission/agency/${user.agency.id}/commissions`
  );
  if (!data) return null;

  return (
    <>
      <CrossDataGrid
        title={"Comisiones por pagos educativos"}
        columns={columns}
        data={data}
        rowId={"id"}
      />
    </>
  );
};
