import { Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import theme from "../../theme";
import styled from "styled-components";
import { Box } from "@mui/system";

export const CrossDataGrid = ({ data, title, columns, rowId, sortModel }) => {
  return (
    <Box width={"100%"}>
      <Grid item xs={12} container justifyContent="lefth" mt={10}>
        <Typography
          component={"div"}
          color={theme.Palette.secondary}
          fontSize={"18px"}
          fontWeight={theme.FontWeight.bold}
          p={2}
        >
          {title}
        </Typography>
      </Grid>
      <StyledMUIGrid
        columnHeaderHeight={30}
        rowHeight={30}
        rows={data ? data : []}
        columns={columns}
        disableColumnSelector
        disableSelectionOnClick
        autoHeight
        pageSizeOptions={[5, 10]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        rowsPerPageOptions={[5, 10]}
        getRowId={(data) => data[rowId]}
        sortModel={sortModel}
        loading={data ? false : true}
      />
    </Box>
  );
};

const StyledMUIGrid = styled(DataGrid)`
  && {
    & .MuiDataGrid-columnHeaderTitle {
      font-size: 14px;
      font-weight: bold;
      color: ${theme.Palette.tertiary};
    }
    & .MuiDataGrid-columnHeaders {
      background-color: ${theme.Palette.senary};
      font-family: ${theme.fontFamily};
    }
    & .MuiDataGrid-row {
      font-size: small;
    }
    & .MuiDataGrid-footerContainer {
      min-height: 25px;
      max-height: 30px;
      line-height: 55px;
    }
  }
`;
