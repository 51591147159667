import React from "react";
import { useForm } from "react-hook-form";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
} from "../../../StyledCrossComponents";
import { mt } from "date-fns/locale";
import { Grid, Typography } from "@mui/material";
import { InputAmountSettle } from "./InputAmountSettle";
import {
  CrossCheck,
  CrossInputText,
  CrossLoader,
  CrossSelectFile,
} from "../../CrossComponents";
import { SelectPayOutMethod } from "./SelectPayOutMethod";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { yupResolver } from "@hookform/resolvers/yup";
import theme from "../../../theme";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { set, sub } from "date-fns";
import { useStorageModal, useStoreModalNotification } from "../../../store";
import { useNavigate } from "react-router-dom";

export const SettleOthersPaymentsForm = ({
  handleNext,
  handleBack,
  activeStep,
  dataSteps,
  dataStepActive,
}) => {
  const { setModalAlert } = useStoreModalNotification();
  const { setModalCross } = useStorageModal();
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const navigate = useNavigate();
  const { validationshema, initialValues } = useValidateForm(
    dataStepActive.form
  );

  const { SubmitFormJson } = useFetchPost();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const formValues = watch();

  const onSubmit = handleSubmit((data) => {
    if (activeStep === dataSteps.length - 1) {
      setLoader(true);
      SubmitFormJson(data, "AgencyPaymentOtherCountry")
        .then((response) => {
          if (!response.ok) {
            setModalAlert({
              openModal: true,
              variant: "filled",
              severity: "error",
              message: "Error al liquidar el pago",
            });
            setLoader(false);
            navigate("/Dashboard/SettlePaymentsList");
          }
          setLoader(false);
          setModalAlert({
            openModal: true,
            variant: "filled",
            severity: "success",
            message: "Pago liquidado con exito",
          });
          handleNext();
        })
        .catch((error) => {
          setLoader(false);
          setModalAlert({
            openModal: true,
            variant: "filled",
            severity: "error",
            message: "Error al liquidar el pago",
          });
          navigate("/Dashboard/SettlePaymentsList");
        });
    } else {
      handleNext();
    }
  });

  return (
    <StyledMUIBoxContainerForm width={"50%"} mt={5}>
      <form noValidate onSubmit={(e) => e.preventDefault()}>
        <Grid container justifyContent="center" spacing={2} p={5}>
          {dataStepActive.form.map((item) => {
            switch (item.componenttype) {
              case "input":
                return (
                  <InputAmountSettle
                    key={item.name}
                    errors={errors}
                    control={control}
                    item={item}
                    formValues={formValues}
                    setValue={setValue}
                  />
                );
              case "inputcross":
                return (
                  <CrossInputText
                    key={item.name}
                    errors={errors}
                    control={control}
                    item={item}
                    formValues={formValues}
                    setValue={setValue}
                  />
                );
              case "select":
                return (
                  <SelectPayOutMethod
                    key={item.nameId ? item.nameId : item.name}
                    errors={errors}
                    control={control}
                    item={item}
                    formValues={formValues}
                    setValue={setValue}
                    nameStep={dataStepActive.nameStep}
                  />
                );
              case "check":
                return (
                  <CrossCheck
                    key={item.name}
                    errors={errors}
                    control={control}
                    item={item}
                    register={register}
                  />
                );
              case "inputfile":
                return (
                  <CrossSelectFile
                    key={item.name}
                    errors={errors}
                    control={control}
                    item={item}
                    formValues={formValues}
                    setValue={setValue}
                  />
                );

              case "NoShow":
                return (
                  <Typography
                    key={item.name}
                    variant="h6"
                    gutterBottom
                    sx={{ display: "none" }}
                  />
                );
              case "Component":
                return (
                  <item.component
                    key={item.name}
                    errors={errors}
                    control={control}
                    item={item}
                    formValues={formValues}
                    setValue={setValue}
                  />
                );

              default:
                return null;
            }
          })}
        </Grid>
      </form>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <StyledMUIButton
            disabled={activeStep === 0}
            backgroundcolor={theme.Palette.secondary}
            onClick={handleBack}
          >
            Regresar
          </StyledMUIButton>
        </Grid>
        <Grid item>
          <StyledMUIButton
            backgroundcolor={theme.Palette.secondary}
            onClick={onSubmit}
          >
            {activeStep === dataSteps.length - 1 ? "Liquidar" : "Siguiente"}
          </StyledMUIButton>
        </Grid>
      </Grid>
    </StyledMUIBoxContainerForm>
  );
};
