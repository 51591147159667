import React from "react";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { useForm } from "react-hook-form";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
} from "../../../StyledCrossComponents";
import {
  CrossInputText,
  CrossLoader,
  CrossSelectList,
} from "../../CrossComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { GetElementsCreateUser } from "../Helpers/GetElementsCreateUser";
import theme from "../../../theme";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { useStorageModal, useStoreModalNotification } from "../../../store";

export const CreateUserForm = () => {
  const elementsForm = GetElementsCreateUser();
  const { validationshema, initialValues } = useValidateForm(elementsForm);
  const { SubmitFormJson } = useFetchPost();
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const formValues = watch();
  const setModalCross = useStorageModal((state) => state.setModalCross);
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const onSubmit = async (data) => {
    setLoader(true);

    const response = await SubmitFormJson(data, "Agency/AddUserByAgency");
    if (response.ok) {
      setLoader(false);
      reset();
      setModalAlert({
        openModal: true,
        message: "Usuario creado con éxito",
        severity: "success",
        variant: "filled",
      });
    }
  };

  return (
    <Grid container justifyContent={"center"} alignItems={"center"} mt={20}>
      <StyledMUIBoxContainerForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={2} p={5}>
            {elementsForm.map((item) => {
              switch (item.componenttype) {
                case "input":
                  return (
                    <CrossInputText
                      key={item.name}
                      control={control}
                      errors={errors}
                      item={item}
                    />
                  );
                case "select":
                  return (
                    <CrossSelectList
                      key={item.nameId ? item.nameId : item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );

                default:
                  return <></>;
              }
            })}
            <Grid container justifyContent={"flex-end"} mt={2}>
              <Grid item>
                <StyledMUIButton
                  type="submit"
                  backgroundcolor={theme.Palette.tertiary}
                >
                  Guardar
                </StyledMUIButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledMUIBoxContainerForm>
    </Grid>
  );
};
