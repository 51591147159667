import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { useStorePaymentStep } from "../../../store";
import { StyledMUIBoxRate } from "../../../StyledCrossComponents";

const getExchangeRate = (countryId) => {
  const { data } = useFetchGet(`Country/${countryId}`);
  if (data === null) return null;
  return data.exchangeRate;
};

const ExchangeRate = ({ setValue, formValues }) => {
  const { paymentStepState } = useStorePaymentStep();
  const setStateGlobalExchangeRate = useStorePaymentStep(
    (state) => state.setPaymentStep
  );
  const exchangeRate = getExchangeRate(formValues.PaymentCountryDestinyId) || 0;
  const userLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    setValue("exchangeRateId", exchangeRate.id || 0);
    setValue("exchangeRate", exchangeRate.exchangeAgency || 0);
    setValue(
      "estimatePayment",
      formValues.targetAmount * exchangeRate.exchangeAgency || 0
    );
    setStateGlobalExchangeRate({
      exchangeAgency: exchangeRate.exchangeAgency || 0,
      acronym: exchangeRate.acronym || "AUD",
    });
  }, [exchangeRate.exchangeAgency]);

  return (
    <Grid item xs={6}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: "12px" } }}>
        {`Tasa de cambio ${exchangeRate.acronym}`} :
      </Typography>
      <StyledMUIBoxRate>
        {exchangeRate &&
          `${1} ${
            paymentStepState.currency
          } = ${exchangeRate.exchangeAgency.toLocaleString(userLanguage, {
            style: "currency",
            currency: exchangeRate.acronym,
          })}`}
      </StyledMUIBoxRate>
    </Grid>
  );
};

export default ExchangeRate;
