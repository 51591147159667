import React, { useEffect } from "react";
import {
  StyledHr,
  StyledMUIBoxContainerForm,
  StyledSpan,
} from "../../../StyledCrossComponents";
import { Stack, Typography } from "@mui/material";
import { useStorePaymentStep } from "../../../store";
import theme from "../../../theme";
import styled from "styled-components";

export const PaymentSumary = ({ formValues }) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const store = useStorePaymentStep();

  const insuranceGrossPrice =
    Object.keys(store.paymentStepState.offeringInsurance).length > 0
      ? store.paymentStepState.offeringInsurance.price.grossPrice
      : 0;
  useEffect(() => {
    const tagetAmountWithInsurance =
      insuranceGrossPrice === 0
        ? 0
        : parseInt(formValues.targetAmount) + insuranceGrossPrice;

    const estimatePaymentWithInsurance =
      tagetAmountWithInsurance * formValues.exchangeRate;

    store.setPaymentStep({
      tagetAmountWithInsurance: tagetAmountWithInsurance,
      estimatePaymentWithInsurance: estimatePaymentWithInsurance,
    });
  }, [insuranceGrossPrice]);

  return (
    <>
      <StyledMUIBoxContainerForm width="100%">
        <Typography
          variant="h6"
          gutterBottom
          sx={{ width: "300px", color: theme.Palette.tertiary }}
        >
          Resumen
        </Typography>
        <StyledHr />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom component="div">
            Tipo de pago:
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {store.paymentStepState.paymentTypeDescription}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            {formValues.paymentTypeId === 1
              ? "Nombre del beneficiario"
              : "Proveedor Educativo"}
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {`${formValues.BeneficiaryFirstName} ${formValues.BeneficiaryLastName}`}
          </Typography>
        </Stack>
        {Object.keys(store.paymentStepState.offeringInsurance).length > 0 && (
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <StyledTypogrphy gutterBottom variant="h6" component="div">
                Nombre del seguro:
              </StyledTypogrphy>
              <Typography gutterBottom variant="body2" component="div">
                {
                  store.paymentStepState.offeringInsurance.offering
                    .offeringInsurance.name
                }
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <StyledTypogrphy gutterBottom variant="h6" component="div">
                Valor del seguro:
              </StyledTypogrphy>
              <Typography gutterBottom variant="body2" component="div">
                {store.paymentStepState.offeringInsurance.price.grossPrice.toLocaleString(
                  userLanguage,
                  {
                    style: "currency",
                    currency: "AUD",
                    currencyDisplay: "code",
                  }
                )}
              </Typography>
            </Stack>
          </>
        )}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            Monto en {store.paymentStepState.currency || "AUD"}:
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {parseInt(formValues.targetAmount).toLocaleString(userLanguage, {
              style: "currency",
              currency: store.paymentStepState.currency || "AUD",
              // currencyDisplay: "code",
            })}
          </Typography>
        </Stack>
        {store.paymentStepState.tagetAmountWithInsurance !== 0 && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <StyledTypogrphy gutterBottom variant="h6" component="div">
              Total en {store.paymentStepState.acronym || "AUD"}:
            </StyledTypogrphy>
            <Typography gutterBottom variant="body2" component="div">
              {store.paymentStepState.tagetAmountWithInsurance.toLocaleString(
                userLanguage,
                {
                  style: "currency",
                  currency: store.paymentStepState.acronym || "AUD",
                }
              )}
            </Typography>
          </Stack>
        )}

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            Monto en (COP):
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {(store.paymentStepState.estimatePaymentWithInsurance !== 0
              ? store.paymentStepState.estimatePaymentWithInsurance
              : formValues.estimatePayment
            ).toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <StyledTypogrphy gutterBottom variant="h6" component="div">
            Tasa de cambio {store.paymentStepState.acronym}:
          </StyledTypogrphy>
          <Typography gutterBottom variant="body2" component="div">
            {formValues.exchangeRate.toLocaleString(userLanguage, {
              style: "currency",
              currency: store.paymentStepState.acronym || "AUD",
              currencyDisplay: "code",
            })}
          </Typography>
        </Stack>
      </StyledMUIBoxContainerForm>
    </>
  );
};

const StyledTypogrphy = styled(Typography)`
  && {
    color: ${theme.Palette.tertiary};
    font-size: small;
    font-weight: bold;
  }
`;
