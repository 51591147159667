export const getDataStepsOthersCounries = (rowData) => {
  const data = [
    {
      label: "Liquidar Pago Agencia",
      step: 0,
      boxWidth: "60%",
      nameStep: "agency",
      validations: [],
      form: [
        {
          componenttype: "NoShow",
          name: "targetAmount",
          value: rowData.targetAmount,
          validations: [],
          xs: 12,
        },
        {
          componenttype: "NoShow",
          name: "paymentReference",
          value: rowData.paymentReference,
          validations: [],
          xs: 12,
        },
        {
          componenttype: "NoShow",
          name: "FullNameReceiverEducationProv",
          value: rowData.beneficiaryFirstName,
          validations: [],
          xs: 12,
        },
        {
          componenttype: "NoShow",
          name: "StellProvePaymenCurrency",
          value: rowData.currency,
          validations: [],
          xs: 12,
        },
        {
          componenttype: "NoShow",
          name: "AgencyId",
          value: rowData.agencyId,
          validations: [],
          xs: 12,
        },
        {
          componenttype: "NoShow",
          name: "StateId",
          value: 4,
          validations: [],
          xs: 12,
        },
        {
          componenttype: "input",
          label: `Digite Comisión de la agencia en ${rowData.currency}`,
          type: "number",
          size: "small",
          name: "stellPaymentValue",
          value: 0,
          margin: "normal",
          xs: 8,
          disabled: false,
          validations: [
            {
              type: "required",
            },
            {
              type: "max-value-Settle",
              value: rowData.targetAmount,
              message: `El valor debe ser menor o igual ${rowData.targetAmount}`,
            },
          ],
        },
        {
          componenttype: "input",
          label: "Tasa de cambio",
          type: "number",
          size: "small",
          name: "settleExchangeAgency",
          value: rowData.settleExchangeAgency,
          margin: "normal",
          xs: 4,
          disabled: true,
          validations: [],
        },
        {
          componenttype: "input",
          label: "Usted recibirá en COP:",
          type: "number",
          size: "small",
          name: "settleAgencyAmountCop",
          value: 0,
          margin: "normal",
          xs: 12,
          disabled: true,
          validations: [],
        },
        {
          componenttype: "select",
          type: "select",
          label: "Tipo de cuenta bancaria",
          size: "small",
          name: "id",
          //filter: [1],
          nameId: "AccountTypeId",
          description: "name",
          value: "0",
          margin: "normal",
          xs: 6,
          apiEndPoint: "AccountType",
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          show: false,
          componenttype: "select",
          label: "Banco",
          type: "select",
          name: "id",
          nameId: "BankId",
          description: "nameBank",
          value: "0",
          size: "small",
          margin: "normal",
          xs: 6,
          apiEndPoint: "Bank",
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Número de cuenta",
          type: "number",
          size: "small",
          name: "AccountNumberCop",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
            {
              type: "min",
              message:
                "El número de identificación debe tener minimo 7 digitos",
              valueMin: 7,
            },
          ],
        },
        {
          componenttype: "select",
          type: "select",
          label: "Tipo de identificación",
          size: "small",
          name: "id",
          //filter: [1],
          nameId: "identificationTypeId",
          description: "description",
          value: "0",
          margin: "normal",
          xs: 12,
          apiEndPoint: "IdentificationType",
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          show: false,
          componenttype: "input",
          label: "Número de identificación",
          type: "number",
          size: "small",
          name: "identificationNumber",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
      ],
    },
    {
      label: "Liquidar Pago Proveedor Educativo",
      step: 2,
      nameStep: "beneficiary",
      boxWidth: "60%",
      validations: [],
      form: [
        {
          componenttype: "input",
          label: "Valor a pagar proveedor educativo",
          type: "number",
          size: "small",
          name: "stellProvePaymentValue",
          value: 0,
          margin: "normal",
          xs: 12,
          disabled: true,
          validations: [],
        },
        {
          componenttype: "select",
          label: "País del Banco",
          size: "small",
          name: "countryId",
          description: "countryName",
          value: rowData.beneficiaryCountryId,
          margin: "normal",
          xs: 6,
          apiEndPoint: "Country",
          disabled: true,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "select",
          label: "Ciudad del banco",
          size: "small",
          name: "cityId",
          description: "nameCity",
          value: rowData.beneficiaryCityId,
          margin: "normal",
          xs: 6,
          apiEndPoint: `City/GetCitiesByCountryId/${rowData.beneficiaryCountryId}`,
          anidado: false,
          param: { name: "countryId" },
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Dirección del Banco",
          type: "text",
          size: "small",
          name: "BankAddress",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Nombre del Banco",
          type: "text",
          size: "small",
          name: "bankName",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
      ],
    },
    // {
    //   label: "Resumen de Liquidación",
    //   step: 2,
    //   boxWidth: "60%",
    //   validations: [],
    //   form: [],
    // },
  ];

  switch (rowData.beneficiaryShortCountryName) {
    case "ES":
    case "UAE":
    case "FR":
    case "IR":
    case "DE":
    case "MT":
    case "UK":
      data[1].form.push(
        {
          componenttype: "input",
          label: "IBAN",
          type: "text",
          size: "small",
          name: "iban",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "SWIFT Code",
          type: "text",
          size: "small",
          name: "swiftCode",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        }
      );
      break;
    case "US":
      data[1].form.push(
        {
          componenttype: "input",
          label: "SWIFT Code",
          type: "text",
          size: "small",
          name: "swiftCode",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Número de cuenta",
          type: "text",
          size: "small",
          name: "accountNumber",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        }
      );
      break;
    case "NZ":
      data[1].form.push(
        {
          componenttype: "input",
          label: "BSB",
          type: "text",
          size: "small",
          name: "bsb",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "SWIFT Code",
          type: "text",
          size: "small",
          name: "swiftCode",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Número de cuenta",
          type: "text",
          size: "small",
          name: "accountNumber",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        }
      );
      break;
    case "CA":
      data[1].form.push(
        {
          componenttype: "input",
          label: "Transit Code",
          type: "text",
          size: "small",
          name: "transitCode",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "SWIFT Code",
          type: "text",
          size: "small",
          name: "swiftCode",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        },
        {
          componenttype: "input",
          label: "Número de cuenta",
          type: "text",
          size: "small",
          name: "accountNumber",
          value: "",
          margin: "normal",
          xs: 12,
          validations: [
            {
              type: "required",
            },
          ],
        }
      );
      break;
    default:
      break;
  }
  return data;
};
