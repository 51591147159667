import React from "react";
import { SettlePaymentHistotyList } from "../Components/SettlePaymentHistotyList";

export const SettlePaymentHistoryList = () => {
  return (
    <>
      <SettlePaymentHistotyList />
    </>
  );
};
