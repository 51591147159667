import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { StyledMUIBoxRate } from "../../../StyledCrossComponents";
import theme from "../../../theme";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { useStorePaymentStep } from "../../../store";

export const ExchangeRatePersons = ({ setValue, formValues }) => {
  const userLanguage = navigator.language || navigator.userLanguage;

  const setStateGlobalExchangeRate = useStorePaymentStep(
    (state) => state.setPaymentStep
  );

  const exchangeRate = getExchangeRate(formValues) || 0;
  let exchange =
    formValues.PaymentCountryOrigenId !== 1
      ? exchangeRate.exchange
      : exchangeRate.exchangeAgency || 0;
  console.log(formValues.PaymentCountryOrigenId);
  console.log(exchange);

  useEffect(() => {
    setValue("exchangeRateId", exchangeRate.id || 0);
    setValue("exchangeRate", exchange);
    //setValue("estimatePayment", formValues.targetAmount * exchange);
    setStateGlobalExchangeRate({
      exchangeAgency: exchange,
      acronym: exchangeRate.acronym || "AUD",
    });
  }, [exchange]);

  return (
    <Grid item xs={6}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: "12px" } }}>
        {`Tasa de cambio ${exchangeRate.acronym}`} :
      </Typography>
      <StyledMUIBoxRate backgroundcolor={theme.Palette.quaternary}>
        {exchangeRate &&
          exchange.toLocaleString(userLanguage, {
            style: "currency",
            currency: exchangeRate.acronym,
          })}
      </StyledMUIBoxRate>
    </Grid>
  );
};

const getExchangeRate = (formValues) => {
  const countryId =
    formValues.PaymentCountryOrigenId !== 1
      ? formValues.PaymentCountryOrigenId
      : formValues.PaymentCountryDestinyId;

  const { data } = useFetchGet(`Country/${countryId}`);
  if (data === null) return null;
  return data.exchangeRate;
};
