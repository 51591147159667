import React from "react";
import {
  StyledHr,
  StyledMUIBoxContainerForm,
  StyledMUIButtonIcon,
  StyledSpan,
} from "../../../StyledCrossComponents";
import { Grid, Stack, Typography } from "@mui/material";
import theme from "../../../theme";
import { useStorageModal } from "../../../store";
import { Link } from "@mui/material";
import { Text } from "../../Home/Styled-components";
import { Style } from "@mui/icons-material";

export const DetailedPaymentSummary = ({ rowData }) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const resetModalCross = useStorageModal((state) => state.resetModalCross);
  const handleClose = () => {
    resetModalCross();
  };

  console.log(rowData);

  return (
    <>
      <StyledMUIBoxContainerForm
        width="40%"
        height="100vh"
        ml={4}
        backgroundColor={theme.Palette.senary}
        //sx={{ textAlign: "center" }}
      >
        <Typography
          sx={{
            color: theme.Palette.quaternary,
          }}
          fontSize={"14px"}
        >
          Detalle del Pago
        </Typography>
        <StyledHr />
        <StackedItem
          label="Referencia de pago:"
          value={rowData.paymentReference}
        />
        <StackedItem label="Tipo de pago:" value={rowData.paymentTypeName} />
        <StackedItem
          label="Proveedor Educativo:"
          value={rowData.beneficiaryFirstName}
        />
        <StackedItem
          label="Pais del proveedor:"
          value={rowData.beneficiaryCountry}
        />
        <StackedItem
          label="Ciudad del proveedor:"
          value={rowData.beneficiarycity}
        />
        <StackedItem
          label="Dirección del proveedor:"
          value={rowData.beneficiaryAddress}
        />
        <StackedItem
          label={`Monto en ${rowData.currency}:`}
          value={rowData.targetAmount.toLocaleString(userLanguage, {
            style: "currency",
            currency: rowData.currency || "AUD",
          })}
        />
        <StackedItem
          label="Monto en (COP):"
          value={rowData.estimatePayment.toLocaleString(userLanguage, {
            style: "currency",
            currency: "COP",
          })}
        />
        <StackedItem
          label={`Tasa de cambio 1 ${rowData.currency}:`}
          value={rowData.exchangeRate.toLocaleString(userLanguage, {
            style: "currency",
            currency: rowData.acronym || "COP",
          })}
        />
        <StackedItem label="Fecha de pago:" value={rowData.paymentDate} />
        <Typography
          sx={{
            color: theme.Palette.quaternary,
          }}
          fontSize={"14px"}
          mt={2}
        >
          Datos del Estudiante
        </Typography>
        <StyledHr />
        <StackedItem
          label="Nombre del estudiante:"
          value={rowData.studentFullName}
        />
        <StackedItem
          label="Número de identificación estudiante:"
          value={rowData.studentIdentificationNumber}
        />
        <StackedItem
          label="Pais del estudiante:"
          value={rowData.studentCountry}
        />
        <StackedItem
          label="Ciudad del estudiante:"
          value={rowData.studentCity}
        />

        <StackedItem
          label="Dirección del estudiante:"
          value={rowData.studenAddress}
        />
        <StackedItem
          label="Teléfono estudiante:"
          value={rowData.studentPhoneNumber}
        />
        <StackedItem
          label="Fecha nacimiento estudiante:"
          value={rowData.studentBirthDate}
        />
        <Stack direction="row" alignItems="center" height="20px">
          <StyledSpan>{"Recibo de pago del estudiante:"}</StyledSpan>
          <Link href={rowData.imageFullPath} target="_blank" fontSize={"12px"}>
            Archivo
          </Link>
        </Stack>
        <Typography
          sx={{
            color: theme.Palette.quaternary,
          }}
          mt={2}
          fontSize={"14px"}
        >
          Datos del pagador
        </Typography>
        <StyledHr />
        <StackedItem
          label="Nombre del Pagador:"
          value={`${rowData.payerFirstName} ${rowData.payerLastName}`}
        />
        <StackedItem
          label="Número de identificación pagador:"
          value={rowData.payerIdentificationNumber}
        />
        <Typography
          sx={{
            color: theme.Palette.quaternary,
          }}
          fontSize={"14px"}
          mt={2}
        >
          Datos del seguro
        </Typography>
        <StyledHr />
        <StackedItem
          label="Nombre del Seguro:"
          value={rowData.offeringInsuranceName}
        />
        <StackedItem label="Valor:" value={rowData.offeringInsuranceValue} />

        <StyledHr />
        <StyledMUIButtonIcon
          variant="contained"
          sx={{ float: "right", marginTop: "10px" }}
          onClick={handleClose}
        >
          Cerrar
        </StyledMUIButtonIcon>
      </StyledMUIBoxContainerForm>
    </>
  );
};
const StackedItem = ({ label, value, link }) => (
  <Stack direction="row" alignItems="center" height="25px">
    <StyledSpan>{label}</StyledSpan>
    <Text fontSize="12px">{value}</Text>
  </Stack>
);
