import React, { useEffect } from "react";
import {
  StyledHr,
  StyledMUIBoxContainerForm,
  StyledSpan,
} from "../../../StyledCrossComponents";
import { Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import { useStorageModal, useStoreSeattlePaymentStep } from "../../../store";
import { SeattlePaymentSteps } from "./SeattlePaymentSteps";
import { useLocation } from "react-router-dom";
import { CrossCurrencyFormatter } from "../../CrossComponents/CrossCurrencyFormatter";

export const DetailedPaymentSettle = () => {
  const setModalCross = useStorageModal((state) => state.setModalCross);
  const location = useLocation();
  const { rowData } = location.state;

  const handleClose = () => {
    setModalCross(false, null);
  };

  console.log(rowData);

  return (
    <>
      <StyledMUIBoxContainerForm width="100%" ml={4} marginTop="10%">
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: theme.Palette.tertiary,
                textAlign: "center",
              }}
            >
              Detalle del Pago
            </Typography>
            <StyledHr />
          </Grid>

          <Grid
            item
            container
            xs={6}
            md={12}
            justifyContent={"center"}
            spacing={5}
          >
            <Grid item>
              <Typography
                variant="h6"
                gutterBottom
                textAlign={"left"}
                sx={{
                  width: "300px",
                  color: theme.Palette.tertiary,
                  fontSize: 12,
                  // display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                <StyledSpan>Referencia de pago: </StyledSpan>
                {rowData.paymentReference}
                <br />
                <StyledSpan>Tipo de pago: </StyledSpan>
                {rowData.paymentTypeName}
                <br />
                <StyledSpan>Proveedor Educativo: </StyledSpan>
                {rowData.beneficiaryFirstName}
                <br />
                <StyledSpan>Pais del Proveedor Educativo: </StyledSpan>
                {rowData.beneficiaryCountry}
                <br />
                <StyledSpan>Ciudad del Proveedor Educativo: </StyledSpan>
                {rowData.beneficiarycity}
                <br />
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                gutterBottom
                textAlign={"left"}
                sx={{
                  width: "300px",
                  color: theme.Palette.tertiary,
                  fontSize: 12,
                  // display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                <StyledSpan>Monto en {rowData.currency || "AUD"}: </StyledSpan>
                <CrossCurrencyFormatter
                  value={rowData.targetAmount}
                  currency={rowData.currency}
                />
                <br />
                <StyledSpan>Monto en (COP): </StyledSpan>
                <CrossCurrencyFormatter
                  value={rowData.estimatePayment}
                  currency={"COP"}
                />
                <br />
                <StyledSpan>Tasa de cambio {rowData.acronym}: </StyledSpan>
                <CrossCurrencyFormatter
                  value={rowData.exchangeRate}
                  currency={rowData.acronym}
                />
                <br />
                <StyledSpan>Fecha de pago: </StyledSpan>
                {rowData.paymentDate}
                <br />
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                gutterBottom
                textAlign={"left"}
                sx={{
                  width: "300px",
                  color: theme.Palette.tertiary,
                  fontSize: 12,
                }}
              >
                <StyledSpan>Nombre del estudiante:</StyledSpan>
                {rowData.studentFullName}
                <br />
                <StyledSpan>Número de identificación estudiante:</StyledSpan>
                {rowData.studentIdentificationNumber}
                <br />
                <StyledSpan>Nombre del Pagador: </StyledSpan>
                {rowData.payerFirstName} {rowData.payerLastName}
                <br />
                <StyledSpan>Número de identificación: </StyledSpan>
                {rowData.payerIdentificationNumber}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </StyledMUIBoxContainerForm>

      <Grid item container xs={6} md={12} justifyContent={"center"}>
        <SeattlePaymentSteps rowData={rowData} />
      </Grid>
    </>
  );
};
