import { Button } from "@mui/material";
import React from "react";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import { useStorageModal } from "../../../store";
import { EnsuranceList } from "./EnsuranceList";

export const EnsuranceButton = ({ visible }) => {
  const setModalCross = useStorageModal((state) => state.setModalCross);

  const handleClick = () => {
    setModalCross({
      openModal: true,
      component: () => <EnsuranceList />,
      setValue: null,
      formValues: null,
    });
  };
  return (
    <>
      {visible && (
        <Button
          variant="contained"
          sx={{ marginTop: "20px", width: "50%" }}
          startIcon={<HealthAndSafetyIcon />}
          onClick={handleClick}
        >
          Comprar Seguro
        </Button>
      )}
    </>
  );
};
