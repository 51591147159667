export const getDataSteps = (rowData) => [
  {
    label: "Liquidar Pago Agencia",
    step: 0,
    boxWidth: "60%",
    nameStep: "agency",
    validations: [],
    form: [
      {
        componenttype: "NoShow",
        name: "targetAmount",
        value: rowData.targetAmount,
        validations: [],
        xs: 12,
      },
      {
        componenttype: "input",
        label: "Digite Comisión de la agencia",
        type: "number",
        size: "small",
        name: "stellPaymentValue",
        value: 0,
        margin: "normal",
        xs: 12,
        disabled: false,
        validations: [
          {
            type: "required",
          },
          {
            type: "max-value-Settle",
            value: rowData.targetAmount,
            message: `El valor debe ser menor o igual ${rowData.targetAmount}`,
          },
        ],
      },
    ],
  },
  {
    label: "Liquidar Pago Proveedor Educativo",
    step: 1,
    nameStep: "beneficiary",
    boxWidth: "60%",
    validations: [],
    form: [
      {
        componenttype: "input",
        label: "Valor a pagar proveedor educativo",
        type: "number",
        size: "small",
        name: "stellProvePaymentValue",
        value: 0,
        margin: "normal",
        xs: 12,
        disabled: true,
        validations: [],
      },
    ],
  },
  {
    label: "Resumen de Liquidación",
    step: 2,
    boxWidth: "60%",
    validations: [],
    form: [],
  },
];
