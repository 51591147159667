import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import { StyledMUITextField } from "../../../StyledCrossComponents";

export const TargetAmount = ({
  control,
  errors,
  item,
  setValue,
  formValues,
}) => {
  return (
    <Grid item xs={item.xs}>
      <Controller
        name={item.name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <StyledMUITextField
              onChange={(e) => {
                field.onChange(+e.target.value);
              }}
              value={field.value}
              label={item.label}
              type={item.type}
              size={item.size}
              sx={item.sx}
            />
            {errors[item.name] && (
              <FormHelperText id="my-helper-text" error>
                {errors[item.name]["message"]}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
};
