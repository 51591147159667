import React from "react";
import { useStorageModal, useStoreModalNotification } from "../../../store";
import {
  StyledHr,
  StyledMUIBoxContainerForm,
  StyledMUIButtonIcon,
} from "../../../StyledCrossComponents";
import theme from "../../../theme";
import { Grid, Typography } from "@mui/material";
import { CrossLoader, CrossStackedItem } from "../../CrossComponents";
import { useFetchPost } from "../../../Hooks/useFetchPost";

export const DetailedSettlePayments = ({ row, rol, navigate }) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const { resetModalCross } = useStorageModal();
  const handleClose = () => {
    resetModalCross();
  };
  const { setModalAlert } = useStoreModalNotification();
  const { SubmitFormJson } = useFetchPost();
  const { setModalCross } = useStorageModal();

  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const handleSettle = () => {
    setLoader(true);
    SubmitFormJson(row, `SettlePaymentProcces/${row.paymentReference}`).then(
      (res) => {
        if (!res.ok) {
          setModalAlert({
            openModal: true,
            message: "Error al liquidar el pago",
            severity: "error",
            variant: "filled",
          });
          handleClose();
          setLoader(false);
          return;
        }
        handleClose();
        setModalAlert({
          openModal: true,
          message: "Pago liquidado con exito",
          severity: "success",
          variant: "filled",
        });
        setLoader(false);
        navigate("/Dashboard/PaymentAdmin");
      }
    );
  };

  return (
    <>
      <StyledMUIBoxContainerForm
        width={{ xs: "100%", sm: "50%" }}
        height="100vh"
        ml={{ xs: 0, sm: "4px" }}
        backgroundColor={theme.Palette.senary}
      >
        <Grid container direction={"column"}>
          <Grid item>
            <Typography
              sx={{
                color: theme.Palette.quaternary,
              }}
              fontSize={"14px"}
            >
              Datos generales de la liquidación
            </Typography>
            <StyledHr />
            <CrossStackedItem
              label="Referencia de pago:"
              value={row.paymentReference}
            />
            <CrossStackedItem
              label="Monto Total:"
              value={row.targetAmount.toLocaleString(userLanguage, {
                style: "currency",
                currency: row.stellProvePaymenCurrency,
              })}
            />
            <Typography
              sx={{
                color: theme.Palette.quaternary,
              }}
              fontSize={"14px"}
            >
              Detalle de la liquidación Agencia
            </Typography>
            <StyledHr />

            <CrossStackedItem
              label="Nombre de la agencia:"
              value={row.agencyName}
            />
            <CrossStackedItem
              label="Tipo de identificación:"
              value={row.identificationTypeName}
            />
            <CrossStackedItem
              label="Número de identificación:"
              value={row.identificationNumber}
            />
            <CrossStackedItem
              label={`Monto a pagar agencia en ${row.stellProvePaymenCurrency}:`}
              value={row.stellPaymentValue.toLocaleString(userLanguage, {
                style: "currency",
                currency: row.stellProvePaymenCurrency,
              })}
            />
            <CrossStackedItem
              label={`Monto a pagar agencia en COP:`}
              value={row.settleAgencyAmountCop.toLocaleString(userLanguage, {
                style: "currency",
                currency: "COP",
              })}
            />
            <CrossStackedItem
              label="Nombre del banco:"
              value={row.bankNameCop}
            />
            <CrossStackedItem
              label="Tipo de cuenta:"
              value={row.accountTypeName}
            />
            <CrossStackedItem
              label="Número de cuenta:"
              value={row.accountNumberCop}
            />
            <Typography
              sx={{
                color: theme.Palette.quaternary,
              }}
              fontSize={"14px"}
            >
              Detalle de la liquidación Proveedor de educación
            </Typography>
            <StyledHr />
            <CrossStackedItem
              label="Nombre del proveedor educativo:"
              value={row.fullNameReceiverEducationProv}
            />
            <CrossStackedItem
              label="Monto a pagar proveedor educativo:"
              value={row.stellProvePaymentValue.toLocaleString(userLanguage, {
                style: "currency",
                currency: row.stellProvePaymenCurrency,
              })}
            />
            <CrossStackedItem label="Pais:" value={row.countryName} />
            <CrossStackedItem label="Ciudad:" value={row.cityName} />
            <CrossStackedItem label="Direccion:" value={row.bankAddress} />
            <CrossStackedItem label="Nombre del banco:" value={row.bankName} />
            {(() => {
              switch (row.countryId) {
                case 4: // USA
                  return (
                    <>
                      <CrossStackedItem
                        label="SWIFTCODE:"
                        value={row.swiftCode}
                      />
                      <CrossStackedItem
                        label="Account Number:"
                        value={row.accountNumber}
                      />
                    </>
                  );
                case 10: // EUA
                case 6: // ESP
                case 8: // Malta
                case 7: // Alemania
                case 11: // Francia
                case 12: // Irlanda
                case 5: // Reino Unido
                  return (
                    <>
                      <CrossStackedItem label="IBAN:" value={row.iban} />
                      <CrossStackedItem
                        label="SWIFTCODE:"
                        value={row.swiftCode}
                      />
                    </>
                  );
                case 9: //NZ
                  return (
                    <>
                      <CrossStackedItem label="BSB:" value={row.bsb} />
                      <CrossStackedItem
                        label="SWIFTCODE:"
                        value={row.swiftCode}
                      />
                      <CrossStackedItem
                        label="Account Number:"
                        value={row.accountNumber}
                      />
                    </>
                  );
                case 3: // Canada
                  return (
                    <>
                      <CrossStackedItem
                        label="Transit Code:"
                        value={row.transitCode}
                      />
                      <CrossStackedItem
                        label="SWIFT Code:"
                        value={row.swiftCode}
                      />
                      <CrossStackedItem
                        label="Account Number:"
                        value={row.accountNumber}
                      />
                    </>
                  );
                default:
                  return null;
              }
            })()}
            <Typography
              sx={{
                color: theme.Palette.quaternary,
              }}
              fontSize={"14px"}
            >
              Estado de la liquidación
            </Typography>
            <StyledHr />
            <CrossStackedItem label="Estado:" value={row.stateName} />
          </Grid>
          <Grid item mt={5}>
            <StyledHr />
            <Grid container spacing={2} justifyContent={"end"}>
              <Grid item>
                <StyledMUIButtonIcon variant="contained" onClick={handleClose}>
                  Cerrar
                </StyledMUIButtonIcon>
              </Grid>
              {row.stateId === 4 && rol.includes("Owner") && (
                <Grid item>
                  <StyledMUIButtonIcon
                    variant="contained"
                    onClick={handleSettle}
                  >
                    Liquidar
                  </StyledMUIButtonIcon>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </StyledMUIBoxContainerForm>
    </>
  );
};
