import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import { StyledMUITextField } from "../../../StyledCrossComponents";
import { useStoreSeattlePaymentStep } from "../../../store";

export const InputAmountSettle = ({
  control,
  errors,
  item,
  formValues,
  setValue,
}) => {
  const setSeattlePayment = useStoreSeattlePaymentStep(
    (state) => state.setSeattlePaymentStep
  );
  const { resetSeattleAgencyErrors, resetSeattleBeneficiaryErrors } =
    useStoreSeattlePaymentStep();

  if (item.name === "stellProvePaymentValue") {
    item.value = formValues.targetAmount - formValues.stellPaymentValue;
  }

  useEffect(() => {
    resetSeattleAgencyErrors();
    resetSeattleBeneficiaryErrors();
    setValue(
      "stellProvePaymentValue",
      parseInt(formValues.targetAmount - formValues.stellPaymentValue)
    );
    setValue(
      "settleAgencyAmountCop",
      formValues.stellPaymentValue * formValues.settleExchangeAgency
    );
    setSeattlePayment({
      agencyPayment: {
        senderAmount: parseInt(formValues.stellPaymentValue),
      },
      beneficiaryPayment: {
        senderAmount: parseInt(
          formValues.targetAmount - formValues.stellPaymentValue
        ),
      },
    });
  }, [formValues.stellPaymentValue]);

  return (
    <Grid item xs={item.xs}>
      <Controller
        name={item.name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <StyledMUITextField
              {...field}
              label={item.label}
              type={item.type}
              size={item.size}
              sx={item.sx}
              value={field.value || item.value}
              disabled={item.disabled}
            />
            {errors[item.name] && (
              <FormHelperText id="my-helper-text" error>
                {errors[item.name]["message"]}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
};
