import React from "react";
import { CrossDataGrid } from "../../CrossComponents";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { set } from "date-fns";
import { ExchangeForm } from "./ExchangeForm";
import { useStorageModal } from "../../../store";
import { useNavigate } from "react-router-dom";

export const ExchangeList = () => {
  const { data, setData } = useFetchGet("ExchangeRate");
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "country",
      headerName: "Pais",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "exchangeAgency",
      headerName: "Agency exchange",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "exchange",
      headerName: "Person Exchange",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "settleExchangeAgency",
      headerName: "Liquidación Exchange",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];
  const { setModalCross } = useStorageModal();
  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <ExchangeForm rowData={row} setData={setData} />,
      setValue: null,
      formValues: null,
    });
  };

  return (
    <>
      <CrossDataGrid columns={columns} data={data} rowId={"id"} />
    </>
  );
};
