import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
  StyledSpan,
} from "../../../StyledCrossComponents";
import {
  CrossCheck,
  CrossDatePicker,
  CrossInputText,
  CrossLoader,
  CrossSearchInput,
  CrossSelectFile,
  CrossSelectList,
} from "../../CrossComponents";
import { EstimatePayment } from "../../Payments/Components/EstimatePayment";
import { TargetAmount } from "../../Payments/Components/TargetAmount";
import { SendOthersPaymentsMethods } from "../../Payments/Components/SendOthersPaymentsMethods";
import theme from "../../../theme";
import styled from "styled-components";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import {
  useStorageModal,
  useStoreFromPayment,
  useStoreModalNotification,
} from "../../../store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { pse } from "../../../assets";
import { ExchangeRatePersons } from "../../Payments/Components/ExchangeRatePersons";
import { SendPersonPaymentLink } from "./SendPersonPaymentLink";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { PersonPaymentSumary } from "./PersonPaymentSumary";
import { SearchPerBeneficiaries } from "./SearchPerBeneficiaries";

export const PersonsPaymentForm = ({
  handleNext,
  handleBack,
  handleReset,
  activeStep,
  dataSteps,
  dataStepActive,
}) => {
  const { validationshema, initialValues } = useValidateForm(
    dataStepActive.form
  );
  const { setModalAlert } = useStoreModalNotification();
  const { setModalCross } = useStorageModal();
  const { SubmitFormJson } = useFetchPost();
  const { fromPaymentState } = useStoreFromPayment();

  const routeApi =
    fromPaymentState.fromPayment === "COP" ? "PerPayment" : "PerPaymentAudCop";

  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const formValues = watch();

  const onSubmit = (data) => {
    if (activeStep === dataSteps.length - 1) {
      setLoader(true);
      SubmitFormJson(data, routeApi).then((response) => {
        if (!response.ok) {
          setModalAlert({
            openModal: true,
            variant: "filled",
            severity: "error",
            message: "Error al enviar el pago",
          });
          setLoader(false);
          return;
        } else {
          setLoader(false);
          setModalAlert({
            openModal: true,
            variant: "filled",
            severity: "success",
            message: "Pago enviado con exito",
          });
          //handleNext();
          reset();
          handleReset();
        }
      });
    } else {
      handleNext();
    }
  };

  return (
    <Grid container alignItems={"center"} justifyContent={"center"}>
      <Grid item xs={12} md={6} mt={10}>
        <StyledMUIBoxContainerForm width={dataStepActive.boxWidth}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent="center" spacing={2} p={5}>
              <Grid
                item
                xs={12}
                display={
                  activeStep === dataSteps.length - 1 &&
                  fromPaymentState.fromPayment === "COP"
                    ? "block"
                    : "none"
                }
              >
                <StyledSpan>Opción 1:</StyledSpan>
              </Grid>
              <StyeleCrossImg
                src={pse}
                alt="pse"
                display={
                  activeStep === dataSteps.length - 1 &&
                  fromPaymentState.fromPayment === "COP"
                    ? "block"
                    : "none"
                }
              />
              {dataStepActive.form.map((item) => {
                switch (item.componenttype) {
                  case "input":
                    return (
                      <CrossInputText
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                      />
                    );
                  case "select":
                    return (
                      <CrossSelectList
                        key={item.nameId ? item.nameId : item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        formValues={formValues}
                        setValue={setValue}
                      />
                    );
                  case "check":
                    return (
                      <CrossCheck
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        register={register}
                      />
                    );
                  case "inputfile":
                    return (
                      <CrossSelectFile
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        formValues={formValues}
                        setValue={setValue}
                      />
                    );

                  case "exchangeRatePersons":
                    return (
                      <ExchangeRatePersons
                        key={item.name}
                        setValue={setValue}
                        formValues={formValues}
                      />
                    );
                  case "estimatePayment":
                    return (
                      <EstimatePayment
                        key={item.name}
                        formValues={formValues}
                        setValues={setValue}
                      />
                    );
                  case "targetAmount":
                    return (
                      <TargetAmount
                        key={item.name}
                        setValue={setValue}
                        formValues={formValues}
                        item={item}
                        control={control}
                        errors={errors}
                      />
                    );
                  case "NoShow":
                    return (
                      <Typography
                        key={item.name}
                        variant="h6"
                        gutterBottom
                        sx={{ display: "none" }}
                      />
                    );

                  case "searchInput":
                    return (
                      <CrossSearchInput
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                        formValues={formValues}
                        setValue={setValue}
                        ComponentModal={() => <SearchPerBeneficiaries />}
                      />
                    );
                  case "datePicker":
                    return (
                      <CrossDatePicker
                        key={item.name}
                        errors={errors}
                        control={control}
                        item={item}
                      />
                    );
                  case "sendPaymentLink":
                    return (
                      <SendPersonPaymentLink setValue={setValue} item={item} />
                    );
                  case "sendOthersPaymentsMethods":
                    return (
                      <SendOthersPaymentsMethods
                        formValues={formValues}
                        handleNext={handleNext}
                        activeStep={activeStep}
                        dataSteps={dataSteps}
                        setValue={setValue}
                        reset={reset}
                      />
                    );
                  case "personPaymentSumary":
                    return (
                      <PersonPaymentSumary
                        formValues={formValues}
                        setValue={setValue}
                      />
                    );

                  default:
                    return null;
                }
              })}
              <Grid container justifyContent={"space-between"} mt={5}>
                <Grid item>
                  <StyledMUIButton
                    backgroundcolor={theme.Palette.secondary}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Regresar
                  </StyledMUIButton>
                </Grid>
                <Grid item>
                  {activeStep !== dataSteps.length - 1 ? (
                    <StyledMUIButton
                      backgroundcolor={theme.Palette.secondary}
                      type="submit"
                    >
                      Siguiente
                    </StyledMUIButton>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </StyledMUIBoxContainerForm>
      </Grid>

      {activeStep == dataSteps.length - 1 &&
      fromPaymentState.fromPayment === "COP" ? (
        <>
          <Grid
            container
            justifyContent={"center"}
            item
            xs={12}
            md={6}
            pl={{ xs: 2, md: 5 }}
            mt={10}
          >
            <PersonPaymentSumary formValues={formValues} />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

const StyeleCrossImg = styled.img`
  width: 20%;
  display: ${(props) => (props.display ? props.display : "block")};
`;
