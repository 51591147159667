import React, { useState } from "react";
import { CrossDataGrid } from "../../CrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button } from "@mui/material";
import { useStorageModal, useUserStore } from "../../../store";
import {
  StyledGridActionsCellItem,
  StyledMUIButtonIcon,
} from "../../../StyledCrossComponents";
import { BeneficiaryForm } from "./BeneficiaryForm";
import { useFetchGet } from "../../../Hooks/useFetchGet";

export const SearchBeneficiary = () => {
  const { user } = useUserStore();
  const state = useStorageModal((state) => state.modalcross);
  const [show, setShow] = useState(false);
  const resetModalCross = useStorageModal((state) => state.resetModalCross);
  const setModalCross = useStorageModal((state) => state.setModalCross);
  const { data, setData, error } = useFetchGet(
    `Beneficiary/GetBeneficiryToAgency/${state.formValues.agencyId}`
  );

  const handleClose = () => {
    resetModalCross();
  };

  const handleShow = () => {
    setShow(true);
  };

  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
      headerAlign: "center",
    },
    {
      field: "beneficiaryFirstName",
      headerName: "Nombre del proveedor",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const handleSelectRow = (row) => {
    state.setValue("beneficiaryFirstName", row.beneficiaryFirstName, true);
    state.setValue("beneficiaryAdress", row.beneficiaryAddress, true);
    state.setValue("beneficiaryId", row.beneficiaryId, true);
    state.setValue("beneficiaryCountryId", row.beneficiaryCountryId, true);
    state.setValue("beneficiaryCityId", row.beneficiaryCityId, true);
    state.setValue("beneficiaryMail", row.beneficiaryMail, true);
    state.setValue("beneficiaryPhoneNumber", row.beneficiaryPhoneNumber, true);
    resetModalCross();
  };

  return (
    <Box
      sx={{
        width: "50%",
        backgroundColor: "white",
        padding: "20px",
        height: "100vh",
      }}
    >
      {!show ? (
        <>
          <CrossDataGrid
            title="Proveedores"
            columns={columns}
            data={data}
            rowId="beneficiaryId"
          />

          <StyledMUIButtonIcon
            marginTop="10px"
            variant="contained"
            sx={{ float: "right" }}
            onClick={handleClose}
          >
            Cerrar
          </StyledMUIButtonIcon>

          <StyledMUIButtonIcon
            marginTop="10px"
            variant="contained"
            sx={{ float: "right", marginRight: "10px" }}
            onClick={handleShow}
          >
            Agregar
          </StyledMUIButtonIcon>
        </>
      ) : (
        <BeneficiaryForm
          beneficiaries={data}
          setData={setData}
          setShow={setShow}
        />
      )}
    </Box>
  );
};
