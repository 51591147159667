import React, { useEffect, useState } from "react";
import { useStorageModal, useUserStore } from "../../../store";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { CrossDataGrid } from "../../CrossComponents";
import { render } from "react-dom";
import { DetailedSettlePayments } from "./DetailedSettlePayments";
import { useNavigate } from "react-router-dom";

export const SettlePaymentList = () => {
  const { setModalCross } = useStorageModal();
  const [gridData, setGridData] = useState([]);
  const userLanguage = navigator.language || navigator.userLanguage;
  const { user } = useUserStore();
  const navigate = useNavigate();
  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "fullNameReceiverEducationProv",
      headerName: "Provedor de educación",
      headerAlign: "center",

      flex: 2,
      minWidth: 200,
    },
    {
      field: "agencyName",
      headerName: "Agencia",
      headerAlign: "center",

      flex: 2,
      minWidth: 200,
    },
    {
      field: "date",
      headerName: "Fecha de liquidación",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const date = new Date(params.row.date);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "targetAmount",
      headerName: "Monto",
      renderCell: (params) => {
        return (
          <>
            {params.row.targetAmount.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.stellProvePaymenCurrency,
            })}
          </>
        );
      },
    },
  ];

  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => (
        <DetailedSettlePayments
          row={row}
          rol={user.roles}
          navigate={navigate}
        />
      ),
      setValue: () => {},
      formValues: {},
    });
  };

  const { data } = useFetchGet("AgencyPaymentOtherCountry");

  useEffect(() => {
    if (!data) return;
    setGridData(data);
  }, [data]);

  return (
    <>
      <CrossDataGrid
        title={"Liquidar pagos"}
        columns={columns}
        data={gridData}
        rowId={"paymentReference"}
        sortModel={[
          {
            field: "date",
            sort: "desc",
          },
        ]}
      />
    </>
  );
};
