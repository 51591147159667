//import "./App.css";
import { RouterProvider, createHashRouter } from "react-router-dom";
import {
  Home,
  Nosotros,
  Service,
  SubService,
  Login,
  PreRegistro,
  RegisterAgency,
  AgencyDashboard,
} from "./Components/Home/Pages";
import { Personas } from "./Components/Home/Pages/Personas";
import { CrossModalNotification } from "./Components/CrossComponents/CrossModalNotification";
import Dashboard from "./Components/Home/Pages/Dashboard";
import { Payments, PaymentsList } from "./Components/Payments/Pages";
import { CrossModal, ProtectedRoute } from "./Components/CrossComponents";
import En from "./Components/Home/Data/Lenguajes/en.json";
import Es from "./Components/Home/Data/Lenguajes/es.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { SettlePaymentsList } from "./Components/SettlePayments/Pages/SettlePaymentsList";
import { DetailedPaymentSettle } from "./Components/SettlePayments/Components";
import { CreateUserByAgency } from "./Components/Configurations/Pages/CreateUserByAgency";
import { PaymentAdmin } from "./Components/Owner/Pages/PaymentAdmin";
import { AgencyAdmin } from "./Components/Owner/Pages/AgencyAdmin";
import { AgencyUserList } from "./Components/Owner/Components/AgencyUserList";
import { Calcualtor } from "./Components/Home/Pages/Calcualtor";
import { ExchangeAdmin } from "./Components/Owner/Pages/ExchangeAdmin";
import { RecoverPassword } from "./Components/Home/Pages/RecoverPassword";
import { ResetPassword } from "./Components/Home/Pages/ResetPassword";
import { RegistrationPerSteps } from "./Components/PersonPayments/Components/RegistrationPerSteps";
import { PersonPayments } from "./Components/PersonPayments/Page/PersonPayments";
import { PaymentPerAudCop } from "./Components/Owner/Pages/PaymentPerAudCop";
import { UsersAdmin } from "./Components/Owner/Pages/UsersAdmin";
import { ValidateIdentity } from "./Components/Configurations/Pages/ValidateIdentity";
import { PersonPaymentList } from "./Components/PersonPayments/Page/PersonPaymentList";
import { PersonVirtualAccount } from "./Components/PersonPayments/Components/PersonVirtualAccount";
import { PayUpAdmin } from "./Components/Owner/Pages/PayUpAdmin";
import { PageTracker } from "./PageTracker";
import { useLenguageStore } from "./store";
import { PaymentPerCopAud } from "./Components/Owner/Pages/PaymentPerCopAud";
import { AgencySettlePayments } from "./Components/Owner/Pages/AgencySettlePayments";
import { CommissionAgencyList } from "./Components/Payments/Components/CommissionAgencyList";
import { SettlePaymentHistoryList } from "./Components/SettlePayments/Pages/SettlePaymentHistory";

function App() {
  const { lenguage } = useLenguageStore();

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: En,
      },
      es: {
        translation: Es,
      },
    },
    lng: lenguage, // language to use
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <>
      <RouterProvider router={router} />
      <CrossModalNotification />
      <CrossModal />
    </>
  );
}

const wrapInProtectedRoute = (routes) => {
  return routes.map((route) => {
    return {
      ...route,
      element: <ProtectedRoute>{route.element}</ProtectedRoute>,
    };
  });
};

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/service",
    element: <Service />,
  },
  {
    path: "/subservice",
    element: <SubService />,
  },
  {
    path: "/Nosotros",
    element: <Nosotros />,
  },
  {
    path: "/Personas",
    element: <Personas />,
  },
  {
    path: "/Login",
    element: <Login />,
  },
  {
    path: "/PreRegistro",
    element: <PreRegistro />,
  },
  {
    path: "/RegisterAgency",
    element: <RegisterAgency />,
  },
  {
    path: "/RegistrationPersons",
    element: <RegistrationPerSteps />,
  },
  {
    path: "/Calculator",
    element: <Calcualtor />,
  },
  {
    path: "/RecoverPassword",
    element: <RecoverPassword />,
  },
  {
    path: "/ResetPassword",
    element: <ResetPassword />,
  },
  {
    path: "/ValidateIdentity",
    element: <ValidateIdentity />,
  },
  {
    path: "/Dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: wrapInProtectedRoute([
      { path: "Payments", element: <Payments /> },
      { path: "PaymentsList", element: <PaymentsList /> },
      { path: "SettlePaymentsList", element: <SettlePaymentsList /> },
      { path: "DetailedPaymentSettle", element: <DetailedPaymentSettle /> },
      { path: "CreateUserByAgency", element: <CreateUserByAgency /> },
      {
        path: "PaymentAdmin",
        element: <PaymentAdmin />,
      },
      {
        path: "AgencyAdmin",
        element: <AgencyAdmin />,
      },
      {
        path: "ExchangeAdmin",
        element: <ExchangeAdmin />,
      },
      {
        path: "AgencyUserList",
        element: <AgencyUserList />,
      },
      {
        path: "UsersAdmin",
        element: <UsersAdmin />,
      },
      { path: "Agency", element: <AgencyDashboard /> },
      { path: "PersonPayments", element: <PersonPayments /> },
      { path: "PaymentPerAudCop", element: <PaymentPerAudCop /> },
      { path: "PersonPaymentList", element: <PersonPaymentList /> },
      { path: "PersonVirtualAccount", element: <PersonVirtualAccount /> },
      { path: "PayUpAdmin", element: <PayUpAdmin /> },
      { path: "PaymentPerCopAud/:userId", element: <PaymentPerCopAud /> },
      {
        path: "AgencySettlePayments",
        element: <AgencySettlePayments />,
      },
      { path: "CommissionAgencyList", element: <CommissionAgencyList /> },
      { path: "SettlePaymentHistory", element: <SettlePaymentHistoryList /> },
      { path: "*", element: <Dashboard /> },
    ]),
  },
  { path: "*", element: <Home /> },
]);
export default App;
