import { Stack } from "@mui/material";
import React from "react";
import { StyledSpan } from "../../StyledCrossComponents";
import { Text } from "../Home/Styled-components";

export const CrossStackedItem = ({ label, value, link }) => {
  return (
    <Stack direction="row" alignItems="center" height="25px">
      <StyledSpan>{label}</StyledSpan>
      <Text fontSize="12px">{value}</Text>
    </Stack>
  );
};
